import PropTypes from 'prop-types';
import React from 'react';
import ConditionsMarkdown from '@/components/ConditionsMarkdown';
import Modal from '@/components/Modal';
import Divider from '@/components/Divider';
import Inclusions from '@/shared/components/Inclusions';
import { Box, Text } from '@qga/roo-ui/components';

const TermAndConditionModal = ({
  onClose,
  termsAndCondition,
  inclusionData,
}) => {
  return (
    <Modal onClose={onClose}>
      <Modal.Header>Package details and terms</Modal.Header>
      <Modal.Body>
        {inclusionData ? (
          <>
            <Box fontSize="md" mb={4} data-testid="HOLIDAY-PACKAGE-TITLE">
              Holiday package to {inclusionData.destination} at{' '}
              {inclusionData.propertyName}
            </Box>
            <Box pb="20px">
              <Box mb={3}>
                <Text>Package inclusions:</Text>
              </Box>
              <Inclusions inclusions={inclusionData.inclusions} showSubtitle />
            </Box>
            <Divider />
          </>
        ) : (
          ''
        )}
        <ConditionsMarkdown markdown={termsAndCondition} />
      </Modal.Body>
    </Modal>
  );
};

TermAndConditionModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  termsAndCondition: PropTypes.string.isRequired,
  inclusionData: PropTypes.shape({
    inclusions: PropTypes.array.isRequired,
    propertyName: PropTypes.string.isRequired,
    destination: PropTypes.string.isRequired,
  }),
};

export default TermAndConditionModal;
